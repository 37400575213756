import React from "react"

import * as S from "./styled"

//@ts-ignore
import { ReactComponent as Logo } from "../../vector/logo.svg"
import { Link } from "gatsby"
// @ts-ignore
import { Helmet } from "react-helmet"

export const LoginPageLayout: React.FC = props => {
  return (
    <S.Container>
      <Helmet>
        <title>Hartog Hoogveld | Login</title>
      </Helmet>
      <S.GlobalStyles />
      <S.InnerContainer>
        <S.Nav>
          <Link to={"/"}>Home</Link>
        </S.Nav>
        <iframe
          src="https://mijndhenh.securelogin.nu/auth/embed"
          style={{ border: "none", minHeight: 200, backgroundColor: "#f3f3f3" }}
        />
      </S.InnerContainer>
      <S.LogoContainer>
        <Logo />
      </S.LogoContainer>
    </S.Container>
  )
}
