import styled, { createGlobalStyle } from "styled-components"
import device from "../../components/Theme/devices"

export const GlobalStyles = createGlobalStyle`
    html,body{overflow: hidden}
    .tl-wrapper {z-index: 200 !important;}
`

export const Container = styled.main`
  background-color: ${props => props.theme.primaryColor};
  background: linear-gradient(
    to bottom,
    ${props => props.theme.primaryColor} 0%,
    ${props => props.theme.primaryColor} 50%,
    white 50%
  );
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
`

export const InnerContainer = styled.div`
  width: 100%;
  max-width: 1440px;

  > iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`

export const LogoContainer = styled.div`
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  @media (${device.tablet}) {
    top: 20%;
  }
  svg {
    width: 100px;
    height: 100px;
  }
`

export const Nav = styled.nav`
  color: white;
  position: absolute;
  top: 20px;
  left: 20px;
  a {
    text-decoration: none;
    color: inherit;
    :hover {
      text-decoration: underline;
    }
  }
`
